<template>
  <div class="homePageWrap">
    <Left />
    <!-- <Right /> -->
  </div>
</template>

<script>
import Grids from "@/components/Grids";
import Left from "./Left";
import Right from "./Right";
export default {
  components: {
    Grids,
    Left,
    Right,
  },
};
</script>

<style scoped lang="scss">
.homePageWrap {
  width: 100%;
  background-color: #fff;
  display: flex;
  position: relative;
  padding: 24px;
  .routerPage {
    position: absolute;
    width: 100%;
    padding: 24px;
    background-color: #fff;
    height: 100%;
  }
}
</style>