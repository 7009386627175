<template>
  <div class="leftContent">
    <div class="commonTitle">辅助工具下载</div>
    <div class="shortActions">
      <el-row :gutter="20">
        <el-col :span="6" v-for="a in shortActions" :key="a.id">
          <a class="action" target="_blank" :href="a.href">
            <img class="icon" :src="a.icon" />
            <span class="text">{{ a.name }}</span>
          </a>
        </el-col>
      </el-row>
    </div>

    <div class="modulesWrap">
      <div class="title">
        <div class="commonTitle">工程模板</div>
        <div class="more" @click="toMorePage">更多模板</div>
      </div>

      <div class="modules">
        <el-row :gutter="20">
          <el-col :span="6" v-for="m in modules" :key="m.key">
            <TemplateC
              :template="m"
              :deleteCommonTemplate="
                isManager ? deleteCommonTemplate : undefined
              "
              :useCommonTemplate="useCommonTemplate"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Grids from "@/components/Grids";
import TemplateC from "@/components/Template";
import { mapState } from "vuex";
export default {
  components: {
    Grids,
    TemplateC,
  },
  data() {
    return {
      shortActions: [],
      modules: [],
    };
  },
  computed: {
    ...mapState(["projectLevels"]),
    ...mapState({
      isManager: (state) => state.loginAccountInfo.is_manage,
    }),
  },
  mounted() {
    this.$store.dispatch("getProjectLevelsAction", {});
    this.getCommonTemplatesList({});
    this.getDownloadList();
  },
  methods: {
    getDownloadList() {
      this.$request.getDownloadList().then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.setGuideUrl(res.data);
        this.shortActions = res.data || [];
      });
    },
    setGuideUrl(datas) {
      if (datas && datas.length > 0) {
        for (let i = 0;i < datas.length;i++) {
          let item = datas[i];
          if (item.name === '快速上手指南') {
            sessionStorage.setItem("newGuideUrl", item.href);
            break;
          }
        }
      }
    },
    toMorePage() {
      this.$router.push("/homePage/moreTemplates");
    },
    getCommonTemplatesList({ page_size = 8, page_num = 1 }) {
      this.$request
        .queryCommonTemplates({ page_size, page_num })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.modules = (res?.data?.result || []).map((t) => ({
            text: t.project_name,
            picture: t.project_img,
            type: t.type,
            id: t.id,
            fileType: "image",
          }));
        });
    },
    deleteCommonTemplate(id) {
      this.$request.deleteCommonTemplates({ id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message.error("删除成功");
        this.getCommonTemplatesList({});
      });
    },
    useCommonTemplate(id, type) {
      const { btns, pages, ip_ports, project_code } = this.projectLevels.find(
        (l) => l.project_code == type
      );
      const limits = btoa(
        JSON.stringify({ project_code, pages, btns, ip_ports })
      );
      this.$router.push({
        path: "/projects/projectsEdit",
        query: {
          limits,
          id,
          isCommonTemplate: true,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.leftContent {
  flex: 1;
  padding-right: 24px;
  .commonTitle {
    font-size: 22px;
  }
  .shortActions {
    .action {
      display: flex;
      align-items: center;
      background-color: rgb(240, 242, 245);
      border-radius: 12px;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      color: #000;
      height: 120px;
      margin-top: 16px;
    }
    .text {
      font-size: 17px;
      margin-left: 10px;
    }
    .icon {
      width: 20%;
    }
  }
  .modulesWrap {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
    }
    .more {
      cursor: pointer;
    }
    .modules {
    }
  }
}
</style>