<template>
  <el-row :gutter="20">
    <el-col :span="6" v-for="(c, i) in gridData" :key="i">
      <slot />
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    gridData: Array,
  },
};
</script>

<style>
</style>